//import TopHeaderLrg from "../components/TopHeaderLrg";
import React, { useEffect } from "react";
import HeroSectionForMotoroamers from "../components/HeroSectionForMotoroamers";
import ContentComponentWhatIsAnAire from "../components/ContentComponentWhatIsAnAire";
import ContentComponentOurMission from "../components/ContentComponentOurMission";
import ContentComponentConnectingMot from "../components/ContentComponentConnectingMot";
import ContentComponentOurStrategy from "../components/ContentComponentOurStrategy";
import FeatureSectionTrustedPartners from "../components/FeatureSectionTrustedPartners";
import FooterPublicLrg from "../components/FooterPublicLrg";
import styles from "./LocoAbout.module.css";

const LocoAbout = () => {







useEffect(() => {
  async function onLoad() {}
  window.scrollTo(0, 0);
  onLoad();
}, []);

  return (
    <div className={styles.locoabout11}>
      <HeroSectionForMotoroamers />
      <ContentComponentWhatIsAnAire />
      <ContentComponentOurMission />
      <ContentComponentConnectingMot />
      <ContentComponentOurStrategy />
      <FeatureSectionTrustedPartners />
      <FooterPublicLrg/>
    </div>
  );
};

export default LocoAbout;
